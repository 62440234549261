import { Analytics } from '@scaleway/use-segment'

export const experimentGrowthBook =
  (analytics?: Analytics) =>
  async ({
    experimentId,
    variationId,
  }: {
    experimentId: string
    variationId: string
  }) => {
    console.log('experimentGrowthBook')
    await analytics?.track('Experiment Viewed', {
      experimentId,
      variationId,
    })
  }

export const devanaTracking = (analytics?: Analytics) => async () => {
  await analytics?.track('DevanaMessage')
}

export const feedbackTracking =
  (analytics?: Analytics) =>
  async ({ url, type }: { url: string; type: string }) => {
    await analytics?.track('Documentation Feedback Clicked', {
      url,
      feedback_type: type,
    })
    await analytics?.queue.flush()
  }
