import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getLocalStorageItem } from '../helpers/localStorage'
import { getQueryParam } from '../helpers/embedded'

const ThemeModeContext = createContext({
  themeMode: 'default',
  toggleThemeMode: () => {},
})

export function ThemeModeProvider({ children }) {
  const [themeMode, setThemeMode] = useState('default')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let loadedDefaultTheme =
      getLocalStorageItem('xstyled-color-mode') || 'default'

    if (getQueryParam('mode') === 'embed') {
      loadedDefaultTheme =
        getQueryParam('theme') === 'dark' ? '_dark' : 'default'
    }

    console.log('loadedDefaultTheme', loadedDefaultTheme)
    setThemeMode(loadedDefaultTheme)
    setLoading(false)
  }, [])

  const toggleThemeMode = useCallback(() => {
    setThemeMode(currentThemeMode =>
      currentThemeMode === '_dark' ? 'default' : '_dark',
    )
  }, [])

  const value = useMemo(
    () => ({
      themeMode,
      toggleThemeMode,
    }),
    [themeMode, toggleThemeMode],
  )

  if (loading) {
    return children
  }

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  )
}

export function useThemeMode() {
  return useContext(ThemeModeContext)
}
